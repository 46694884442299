import React from "react";
import { createUseStyles } from "react-jss";

interface ImageProps {
  img: string;
  className: any;
  background?: "cover" | "contain";
}

const useStyles = createUseStyles({
  actualImageWrapper: {
    backgroundSize: ({ background }: ImageProps) => {
      switch (background) {
        case "cover":
          return "cover !important";
        case "contain":
          return "contain !important";
        default:
          return "contain !important";
      }
    },
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
  },
});

const Image: React.FC<ImageProps> = (props) => {
  const classes = useStyles(props);

  return (
    <div
      className={` ${classes.actualImageWrapper} ${props.className}`}
      style={{
        background: `url(${props.img})`,
      }}
    />
  );
};
export { Image };
