import "../htmlContent.css";
import * as Sentry from "@sentry/node";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Layout, message } from "antd";
import "antd/dist/antd.css";
import { useTranslation } from "next-i18next";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import "nprogress/nprogress.css";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { createUseStyles } from "react-jss";
import { firebase, InternetStatus, setToken, TopProgressBar } from "shared";
import { auth } from "shared/src/firebase";
import { SideNav } from "../components";
import { UserRoleType } from "../interfaces/admin-member.interface";
import "../main.scss";

type ContextProps = {
  loading: boolean;
  user: firebase.User | null;
  authenticated: boolean;
  setUser: any;
  decodedToken: any;
  setDecodedToken: any;
  setLoading: any;
  token: any;
  role: UserRoleType;
};

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `admin-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

export const AuthContext = React.createContext<Partial<ContextProps>>({});
const useStyles = createUseStyles({
  siderMain: {
    backgroundColor: "#FFFFFF",
  },
  mainLayout: {
    backgroundColor: "#ffffff",
    borderLeft: "1px solid #f0f0f0",
  },
});
const MyApp = ({ Component, pageProps }: AppProps) => {
  const { Sider, Content } = Layout;
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null as firebase.User | null);
  const { pathname } = useRouter();
  const { t } = useTranslation();
  const [role, setRole] = useState<UserRoleType>();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 0,
            gcTime: 0,
            retry: false,
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  useEffect(() => {
    handleUser();
  }, [pathname]);

  const handleUser = () => {
    auth().onAuthStateChanged(async (user) => {
      if (user !== null) {
        const idToken = await user?.getIdTokenResult();
        if (idToken.claims["user"]) {
          setLoading(false);
          message.error("You are not authorized.");
          return;
        }
        if (idToken.claims["admin"]) {
          setRole("admin");
        }
        if (idToken.claims["carpet_editor"]) {
          setRole("carpet_editor");
        }
        if (idToken.claims["article_editor"]) {
          setRole("article_editor");
        }
        setUser(user);
        setToken(idToken.token);
      }
      setLoading(false);
    });
  };

  const classes = useStyles();
  return (
    <>
      <InternetStatus
        noNotification
        window={global.window}
        message={t("No Internet")}
        description={t(
          "Internet connection couldn't be established, please check your connection."
        )}
      />
      <TopProgressBar />
      <QueryClientProvider client={queryClient}>
        <AuthContext.Provider
          value={{
            loading,
            user,
            authenticated: user !== null,
            setUser,
            setLoading,
            role,
          }}
        >
          {user ? (
            <div
              style={{
                overflow: "auto",
              }}
            >
              <Layout>
                <Sider
                  className={classes.siderMain}
                  width="240px"
                  trigger={null}
                >
                  <SideNav />
                </Sider>
                <Layout className={classes.mainLayout}>
                  <div className="layoutStyle">
                    <Content className="contentStyle">
                      <Component {...pageProps} />
                    </Content>
                  </div>
                </Layout>
              </Layout>
            </div>
          ) : (
            <Component {...pageProps} />
          )}
        </AuthContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
};

export default MyApp;
