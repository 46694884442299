import { Select as AntSelect } from "antd";
import React, { useState, useEffect, FocusEvent } from "react";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { Typography } from "../../atoms/Typography";
import styles from "./select.module.scss";

const { Option } = AntSelect;

interface SelectOption {
  value: string;
  display?: string;
  disabled?: boolean;
}

interface SelectProps {
  size?: SizeType;
  value?: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  allowClear?: boolean;
  options: SelectOption[];
  error?: string;
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  onChange?: (str: string) => void;
}

const Select: React.FC<SelectProps> = ({
  defaultValue,
  options,
  size,
  allowClear,
  label,
  placeholder,
  onChange,
  onBlur,
  error,
  value,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(
    defaultValue || null
  );

  useEffect(() => {
    onChange && onChange(selectedValue);
  }, [selectedValue]);

  const mapOptions = (option: SelectOption) => {
    return (
      <Option
        className="select-option"
        disabled={option.disabled}
        key={option.value}
        value={option.value}
      >
        {option.display || option.value}
      </Option>
    );
  };

  const handleSelectChange = (val) => setSelectedValue(val);

  return (
    <div>
      <div className={styles.antSelectWrapper}>
        {label && <Typography className={styles.label}>{label}</Typography>}
        <AntSelect
          onBlur={onBlur}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value || selectedValue}
          size={size}
          allowClear={allowClear}
          onChange={handleSelectChange}
        >
          {options && options.map(mapOptions)}
        </AntSelect>
      </div>
      {error && <Typography className={styles.errorText}>{error}</Typography>}
    </div>
  );
};

export type { SelectProps, SelectOption };

export { Select };
