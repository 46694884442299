import { GetServerSidePropsContext } from "next";
import { ParsedUrlQuery } from "querystring";
import { parseCookies } from "nookies";

interface FirebaseToken {
  iss: string;
  aud: string;
  auth_time: number;
  user_id: string;
  sub: string;
  iat: number;
  exp: number;
  email: string;
  email_verified: false;
  firebase: {
    identities: {
      email: string[];
    };
    sign_in_provider: string;
  };
}

/**
 * Checks if firebase token is present in cookie
 * for nextjs server side rendering
 *
 * @param ctx nextjs context
 */
const withFirebaseToken = async (
  ctx: GetServerSidePropsContext<ParsedUrlQuery>
): Promise<FirebaseToken> => {
  if (typeof window === "undefined") {
    const { firebaseToken } = parseCookies(ctx);
    const parsedToken = JSON.parse(firebaseToken) as FirebaseToken;
    return parsedToken;
  }
  return null;
};

export { withFirebaseToken };
