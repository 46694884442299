import React, { useContext, useEffect } from "react";
import { UserRoleType } from "./interfaces/admin-member.interface";
import { AuthContext } from "./pages/_app";
import Router from "next/router";
import { Spin } from "antd";
import Head from "next/head";

const PrivateRoute = (
  AuthComponent: React.ComponentType,
  userRole?: UserRoleType
) => {
  const PrivateComponent = ({ children }: { children: React.ReactNode }) => {
    const {
      authenticated = false,
      loading = true,
      user,
      role,
    } = useContext(AuthContext);
    const [hasAccess, setHasAccess] = React.useState(false);

    useEffect(() => {
      const { pathname } = Router;
      if (loading) return;
      if (!authenticated) {
        Router.push("/login");
        return;
      }
      if (authenticated && pathname === "/") {
        if (role === "article_editor") {
          Router.push("/articles");
        } else {
          Router.push("/carpets");
        }
      }
    }, [loading, authenticated, user]);

    useEffect(() => {
      if (loading) return;
      if (role === "admin") {
        setHasAccess(true);
        return;
      }
      if (userRole && role === userRole) {
        setHasAccess(true);
      } else {
        setHasAccess(false);
        if (role === "article_editor") {
          Router.push("/articles");
        } else {
          Router.push("/carpets");
        }
      }
    }, [role, userRole, loading]);

    if (loading) {
      return (
        <>
          <Head>
            <title>Golestan(ゴレスタン)|高級ペルシャ絨毯専門店</title>
          </Head>
          <div className="loader">
            <Spin size="large" className="loader" />
          </div>
        </>
      );
    }
    return <>{authenticated && hasAccess && <>{children}</>}</>;
  };

  return class Higher extends React.Component {
    render() {
      return (
        <PrivateComponent>
          <AuthComponent {...(this.props as any)} />
        </PrivateComponent>
      );
    }
  };
};

export default PrivateRoute;
