import React from "react";
import { Rate as RatingComponent } from "antd";
import { createUseStyles } from "react-jss";
import { Typography } from "../Typography";

interface RatingProps {
  value?: number;
  label: string;
  onChange?: any;
  disabled?: boolean;
}

const useStyles = createUseStyles({
  rating: {
    color: "#8D1713",
  },
  specificationDetailsWrapper: {
    display: "flex",
    marginBottom: 12,
  },
  specificationHeadings: {
    maxWidth: 200,
    minWidth: 90,
    display: "flex",
    alignItems: "center",
  },
  specificationNameWrapper: {},
  ratingWrapper: {},
  text: {
    fontWeight: "bold",
    "@media(max-width: 769px)": {
      fontSize: 12,
      lineHeight: "17px",
      marginBottom: 12,
      fontWeight: 400,
    },
  },
});

const Rate: React.FC<RatingProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.specificationDetailsWrapper}>
      <div className={classes.specificationHeadings}>
        <div className={classes.specificationNameWrapper}>
          <Typography type="body3" className={classes.text}>
            {props.label}:
          </Typography>
        </div>
      </div>
      <div className={classes.ratingWrapper}>
        <RatingComponent
          count={5}
          value={props.value}
          className={classes.rating}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};

export { Rate };
