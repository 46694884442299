import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";

interface Props {
  placeholder: string;
  loading?: boolean;
  className?: string;
  onChange?: (name: string, searchFor: string) => void;
  name?: string;
}

const useStyles = createUseStyles({
  search: {
    borderRadius: 2,
    padding: "13px 9px 13px 9px",
    border: "1px solid #D9D9D9",
    "& input": {
      fontSize: "14px",
      lineHeight: "20px",
    },
    "& .anticon": {
      color: "#000",
    },
  },
});

const Search: React.FC<Props> = ({
  onChange,
  name,
  loading,
  className,
  placeholder,
}) => {
  const classes = useStyles();

  const [value, setValue] = useState("" as string);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setValue(event.target.value);
    let searchFor = event.target.value;
    searchFor = searchFor.toLocaleLowerCase();
    if (onChange) {
      onChange(name || "", searchFor);
    }
  };

  const icon = loading ? <LoadingOutlined /> : <SearchOutlined />;

  return (
    <Input
      type="string"
      value={value}
      onChange={handleChange}
      className={`${classes.search} ${className}`}
      suffix={icon}
      placeholder={placeholder}
    />
  );
};

export { Search };
