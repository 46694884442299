import { Tag as TagComponent } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

export interface Props {
  className?: any;
  children: any;
  type: "booked" | "sold" | "new" | "green";
}

const useStyles = createUseStyles({
  tags: {
    padding: "6px 14px",
    borderRadius: 10,
    fontSize: 10,
    lineHeight: "15px",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    border: "none",

    background: ({ type }: Props) => {
      switch (type) {
        case "booked":
          return "#FBC02D";
        case "sold":
          return "#BDBDBD";
        case "new":
          return "#F44336";
        case "green":
          return "#009688";
        default:
          return "#BDBDBD";
      }
    },
    "&:hover": {
      background: ({ type }: Props) => {
        switch (type) {
          case "booked":
            return "#FBC02D";
          case "sold":
            return "#BDBDBD";
          case "new":
            return "#F44336";
          case "green":
            return "#009688";
          default:
            return "#BDBDBD";
        }
      },
    },
    "&:focus": {
      background: ({ type }: Props) => {
        switch (type) {
          case "booked":
            return "#FBC02D";
          case "sold":
            return "#BDBDBD";
          case "new":
            return "#F44336";
          default:
            return "#BDBDBD";
        }
      },
    },
    "&:active": {
      background: ({ type }: Props) => {
        switch (type) {
          case "booked":
            return "#FBC02D";
          case "sold":
            return "#BDBDBD";
          case "new":
            return "#F44336";
          default:
            return "#BDBDBD";
        }
      },
    },
  },
});

const Tag: React.FC<Props> = ({ className, children, type }) => {
  const classes = useStyles({ type });

  return (
    <TagComponent className={`${classes.tags} ${className}`}>
      {children}
    </TagComponent>
  );
};

export { Tag };
