import {
  AppstoreOutlined,
  BarsOutlined,
  CommentOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MessageOutlined,
  PictureOutlined,
  TransactionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { auth } from "firebase";
import { TFunction } from "next-i18next";
import { useRouter } from "next/router";
import { destroyCookie } from "nookies";
import React, { useContext } from "react";
import { removeToken } from "shared";
import { UserRole } from "../interfaces/admin-member.interface";
import { AuthContext } from "../pages/_app";

export const useGetMenuItems = ({ t }: { t: TFunction }) => {
  const router = useRouter();

  const { setUser, role } = useContext(AuthContext);

  const handleSignOut = async () => {
    await auth().signOut();
    destroyCookie({}, "accessToken", {
      path: "/",
    });
    localStorage.clear();
    removeToken();
    setUser(null);
    router.push("/login", "/login", {
      shallow: true,
    });
  };
  const handleReviews = () => {
    router.push("/reviews", "/reviews", {
      shallow: true,
    });
  };

  const handleComments = () => {
    router.push("/comments", "/comments", {
      shallow: true,
    });
  };

  const handlePurchaseHistory = () => {
    router.push("/purchase-history", "/purchase-history", {
      shallow: true,
    });
  };

  const handleCarpetClick = () => {
    router.push("/carpets", "/carpets", {
      shallow: true,
    });
  };
  const handleUserListClick = () => {
    router.push("/user-lists", "/user-lists", {
      shallow: true,
    });
  };
  const handleArticleClick = () => {
    router.push("/articles", "/articles", {
      shallow: true,
    });
  };
  const handleGuideClick = () => {
    router.push("/guides", "/guides", {
      shallow: true,
    });
  };
  const handleInteriorClick = () => {
    router.push("/interior", "/interior", {
      shallow: true,
    });
  };
  const handleWorkshopClick = () => {
    router.push("/workshop", "/workshop", {
      shallow: true,
    });
  };

  const handleLPImagesClick = () => {
    router.push("/lp-images", "/lp-images", {
      shallow: true,
    });
  };

  const handleAdminMemberListClick = () => {
    router.push("/admin-member-list", "/admin-member-list", {
      shallow: true,
    });
  };

  const menuItems = [
    {
      key: "carpets",
      onClick: handleCarpetClick,
      icon: <AppstoreOutlined />,
      name: t("Carpets"),
      role: [UserRole.carpet_editor],
    },
    {
      key: "workshop",
      onClick: handleWorkshopClick,
      icon: <BarsOutlined />,
      name: t("Workshop"),
    },
    {
      key: "reviews",
      onClick: handleReviews,
      icon: <MessageOutlined />,
      name: t("Reviews"),
    },
    {
      key: "/comments",
      onClick: handleComments,
      icon: <CommentOutlined />,
      name: t("Comments"),
    },
    {
      key: "/purchase-history",
      onClick: handlePurchaseHistory,
      icon: <TransactionOutlined />,
      name: t("Purchase History"),
    },
    {
      key: "article",
      onClick: handleArticleClick,
      icon: <FileTextOutlined />,
      name: t("Article Management"),
      role: [UserRole.article_editor],
    },
    {
      key: "/interior",
      onClick: handleInteriorClick,
      icon: <AppstoreOutlined />,
      name: t("Interior Carpet Management"),
    },
    {
      key: "/admin-member-list",
      onClick: handleAdminMemberListClick,
      icon: <UserOutlined />,
      name: t("Admin Member List"),
    },
    {
      key: "/user-lists",
      onClick: handleUserListClick,
      icon: <UserOutlined />,
      name: t("User Lists"),
    },
    {
      key: "guide",
      onClick: handleGuideClick,
      icon: <InfoCircleOutlined />,
      name: t("Guide Management"),
    },
    {
      key: "/lp-images",
      onClick: handleLPImagesClick,
      icon: <PictureOutlined />,
      name: t("LP Images"),
    },
    {
      key: "",
      onClick: handleSignOut,
      icon: <LogoutOutlined />,
      name: t("Sign out"),
      role: [UserRole.admin, UserRole.carpet_editor, UserRole.article_editor],
    },
  ];

  let filteredMenuItems = [];

  if (!role) {
    return {
      menuItems: [],
    };
  }

  if (role === "admin") {
    filteredMenuItems = menuItems.map((item) => {
      return {
        key: item.key,
        onClick: item.onClick,
        icon: item.icon,
        name: item.name,
      };
    });
  } else {
    filteredMenuItems = menuItems.filter((item) => {
      return item?.role?.includes(role);
    });
  }

  return {
    menuItems: filteredMenuItems,
  };
};
