import React from "react";
import { createUseStyles } from "react-jss";
import FullLogo from "../../assets/full_logo.svg";
import SmallFullLogoWhite from "../../assets/small_full_logo_header_white.svg";
import SmallFullLogo from "../../assets/small_full_logo_header.svg";
import Image from "next/image";

const useStyle = createUseStyles({
  logoImage: { cursor: "pointer" },
});

interface Props {
  theme: "white" | "dark";
  size?: "small" | "normal";
}

const LogoHeader: React.FC<Props> = (props: Props) => {
  const classes = useStyle();
  return (
    <Image
      className={classes.logoImage}
      src={
        props.theme === "dark"
          ? SmallFullLogoWhite
          : props.size === "small"
            ? SmallFullLogo
            : FullLogo
      }
      width={348}
      height={110}
      priority
      decoding="sync"
      alt="ペルシャ絨毯専門店 ゴレスタン"
    />
  );
};

export { LogoHeader };
