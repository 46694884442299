import React from "react";
import { Layout, Badge, Avatar } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { createUseStyles } from "react-jss";
import { Search } from "../../atoms/Search";
export interface Props {
  collapsed: boolean;
  handleToggle: any;
}

const useStyles = createUseStyles({
  header: {
    backgroundColor: "#FFFFFF",
    zIndex: 1000,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "inset 0px 0px 1px 1px #F0F0F0",
    padding: "0px 24px",
    "& div": {
      width: "170px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      height: "100%",
    },
  },
  user: {
    fontSize: 13,
    lineHeight: "64px",
    marginLeft: 8,
  },
  notification: {
    width: 12,
    height: 17,
  },
  badge: {
    left: 5,
    "& .ant-badge-count": {
      top: "-8px",
      left: "0.1px",
      border: ".5px solid #ff4d4f",
      boxShadow: "0 0 0 1px #ff4d4f",
    },
  },
  search: {
    width: 193,
    height: 40,
  },
});

const Header = (props: Props) => {
  const { collapsed, handleToggle } = props;
  const { Header } = Layout;
  const classes = useStyles();
  return (
    <React.Fragment>
      <Header className={classes.header}>
        <Search className={classes.search} placeholder="Search" />
        <div>
          <Badge className={classes.badge} count={8}>
            <BellOutlined className={classes.notification} />
          </Badge>
          <div>
            <Avatar
              shape="circle"
              size={24}
              src="https://res.cloudinary.com/di23q5uen/image/upload/v1599206391/image_o1mid5.png"
            />
            <span className={classes.user}>Bruce_banner</span>
          </div>
        </div>
      </Header>
    </React.Fragment>
  );
};

export { Header };
