import { Input } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

interface Props {
  value?: string;
  label?: string;
  error?: string;
  required?: boolean;
  onBlur?: any;
  name: string;
  placeholder?: string;
  rows?: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  inputClass?: any;
  disabled?: boolean;
  autoSize?: boolean;
  autoFocus?: boolean;
}

const useStyles = createUseStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  text: {
    lineHeight: "30px",
    "&::-webkit-scrollbar-track": {
      background: "#000",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "10px",
    },
  },
  label: { marginBottom: "0", fontSize: 14, color: "#1b2e32" },
  errorText: {
    color: "red",
  },
});

export const TextArea = ({
  label,
  required,
  value,
  onBlur,
  name,
  placeholder,
  rows,
  onChange,
  inputClass,
  disabled = false,
  autoSize,
  autoFocus,
  error,
}: Props) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event);
  };

  return (
    <div className={classes.container}>
      {label && (
        <p className={classes.label}>
          {label} {required ? "*" : null}
        </p>
      )}

      <Input.TextArea
        className={`${classes.text} ${inputClass}`}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        onBlur={onBlur}
        autoFocus={autoFocus}
        autoSize={autoSize}
        rows={rows || 7}
        disabled={disabled}
      />
      {error !== "" && <p className={classes.errorText}>{error}</p>}
    </div>
  );
};
