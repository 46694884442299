import { Input, Typography } from "antd";
import React from "react";

import { createUseStyles } from "react-jss";

export interface TextFieldProps {
  placeholder?: string;
  type?: string;
  onChange?: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  name?: string;
  className?: any;
  bordered?: boolean;
  value?: string;
  disabled?: boolean;
  icon?: any;
  label?: string;
  maxLength?: number;
  caption?: string;
  error?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;

  autoComplete?: "on" | "off";
  id?: string;
}

const useStyles = createUseStyles({
  captionWrapper: {
    positon: "relative !important",
  },
  caption: {
    maxWidth: 315,
    display: "flex",
    float: "right",
    fontSize: 16,
    lineHeight: "23px",
    color: "rgba(0, 0, 0, 0.38)",
  },
  errorText: {
    color: "red",
  },
});

const { Text } = Typography;

const TextField: React.FC<TextFieldProps> = (props) => {
  const { TextArea } = Input;
  const classes = useStyles();

  const bordered = props.bordered;

  return props.type === "password" ? (
    <div>
      <Text>{props.label}</Text>
      <Input.Password
        className={`${props.className}`}
        placeholder={props.placeholder}
        name={props.name}
        size={"large"}
        value={props.value}
        disabled={props.disabled}
        prefix={props.icon}
        onChange={props.onChange}
        onBlur={props.onBlur}
        data-testid="password-field"
      />
      <div className={classes.captionWrapper}>
        <Typography className={classes.caption}>{props.caption}</Typography>
      </div>

      {props.error != "" && <p className={classes.errorText}>{props.error}</p>}
    </div>
  ) : props.type === "textarea" ? (
    <div>
      <Typography>{props.label}</Typography>
      <TextArea
        className={`${props.className} input`}
        placeholder={props.placeholder}
        name={props.name}
        maxLength={props.maxLength}
        value={props.value}
        bordered={bordered}
        defaultValue={props.value}
        prefix={props.icon}
        disabled={props.disabled}
        onChange={props.onChange}
        id={props.id}
        autoComplete={props.autoComplete}
      />
      <div className={classes.captionWrapper}>
        <Typography className={classes.caption}>{props.caption}</Typography>
      </div>
      {props.error !== "" && <p className={classes.errorText}>{props.error}</p>}
    </div>
  ) : (
    <div>
      <Typography>{props.label}</Typography>
      <Input
        type={props.type}
        className={`${props.className}`}
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        prefix={props.icon}
        size={`large`}
        disabled={props.disabled}
        onChange={props.onChange}
        onBlur={props.onBlur}
        id={props.id}
        autoComplete={props.autoComplete}
        maxLength={props.maxLength}
      />
      <div className={classes.captionWrapper}>
        <Text className={classes.caption}>{props.caption}</Text>
      </div>
      {props.error !== "" && <p className={classes.errorText}>{props.error}</p>}
    </div>
  );
};
export { TextField };
