import draftToHtml from "draftjs-to-html";

export function detectContentFormat(content: string) {
  try {
    const parsed = JSON.parse(content);
    if (
      parsed.blocks &&
      Array.isArray(parsed.blocks) &&
      parsed.entityMap !== undefined
    ) {
      return draftToHtml(parsed);
    }
    return parsed;
  } catch (e) {
    return content;
  }
}
