import {
  AppstoreOutlined,
  BarsOutlined,
  CommentOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MessageOutlined,
  PictureOutlined,
  TransactionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { destroyCookie } from "nookies";
import React, { useContext, useMemo } from "react";
import { createUseStyles } from "react-jss";
import { MenuGenerator, removeToken } from "shared";
import { auth } from "shared/src/firebase";
import { AuthContext } from "../../../pages/_app";
import Link from "next/link";
import Image from "next/image";
import { useGetMenuItems } from "../../../lib/use-menu-items";

const useStyles = createUseStyles({
  menu: {
    color: "#000000",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: "normal",
    "& ul": {
      "li :before": {
        backgroundColor: "#fff",
      },
    },
    "& .ant-menu-item-selected:after ": {
      opacity: 0,
      backgroundColor: "#fff",
    },
  },
  title: {
    fontSize: 20,
    fontWeight: "normal",
    lineHeight: "63px",
    color: "#1890FF",
    marginLeft: 24,
  },
});
const SideNav: React.FC = () => {
  const { t } = useTranslation();
  const { setUser } = useContext(AuthContext);
  const router = useRouter();
  const { menuItems } = useGetMenuItems({ t });

  const handleSignOut = async () => {
    await auth().signOut();
    destroyCookie({}, "accessToken", {
      path: "/",
    });
    removeToken();
    setUser(null);
    router.push("/login", "/login", {
      shallow: true,
    });
  };

  const handleReviews = () => {
    router.push("/reviews", "/reviews", {
      shallow: true,
    });
  };

  const handleComments = () => {
    router.push("/comments", "/comments", {
      shallow: true,
    });
  };

  const handlePurchaseHistory = () => {
    router.push("/purchase-history", "/purchase-history", {
      shallow: true,
    });
  };
  const classes = useStyles();

  const handleCarpetClick = () => {
    router.push("/carpets", "/carpets", {
      shallow: true,
    });
  };
  const handleUserListClick = () => {
    router.push("/user-lists", "/user-lists", {
      shallow: true,
    });
  };
  const handleArticleClick = () => {
    router.push("/articles", "/articles", {
      shallow: true,
    });
  };
  const handleGuideClick = () => {
    router.push("/guides", "/guides", {
      shallow: true,
    });
  };
  const handleInteriorClick = () => {
    router.push("/interior", "/interior", {
      shallow: true,
    });
  };
  const handleWorkshopClick = () => {
    router.push("/workshop", "/workshop", {
      shallow: true,
    });
  };

  const handleLPImagesClick = () => {
    router.push("/lp-images", "/lp-images", {
      shallow: true,
    });
  };

  const selectedKey = useMemo(() => {
    const matchedItem = menuItems.find((item) =>
      router.pathname.includes(item.key)
    );
    return matchedItem ? matchedItem.key : "/carpets";
  }, [router.pathname, menuItems]);

  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          background: "#FFFFFF",
          boxShadow: " inset 0px -1px 0px #F0F0F0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBlock: 11,
        }}
      >
        <Link href={"/"} passHref>
          <a>
            <Image
              src="/assets/golestan-logo-new.svg"
              alt="Golestan"
              height={36}
              width={156}
              priority
              quality={100}
            />
          </a>
        </Link>
      </div>
      {menuItems && menuItems.length > 0 && (
        <MenuGenerator
          className={classes.menu}
          theme="light"
          mode="inline"
          defaultSelectedKeys={[selectedKey]}
          defaultOpenKeys={[selectedKey]}
          items={menuItems}
        />
      )}
    </div>
  );
};

export { SideNav };
