import { Button as ButtonComponent } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

type ButtonType = "default" | "primary";

export interface Props {
  type?: ButtonType;
  className?: any;
  disabled?: boolean;
  htmlType?: "submit" | "button" | "reset";
  loading?: boolean;
  size?: "small" | "middle" | "large";
  margin?: any;
  shape?: "circle" | "circle-outline" | "round";
  ghost?: boolean;
  icon?: any;
  prefix?: any;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const useStyles = createUseStyles({
  button: {
    position: "relative",
    "& .ant-btn-primary": {
      border: "none",
      borderColor: "none",
    },
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24)",
    borderRadius: 2,
    border: "none",
    margin: ({ margin }: Props) => {
      return margin;
    },
    background: ({ type }: Props) => {
      switch (type) {
        case "default":
          return "#9C27B0";
        case "primary":
          return "#009688";
        default:
          return "#009688";
      }
    },
    "&:focus": {
      background: ({ type }: Props) => {
        switch (type) {
          case "default":
            return "#9C27B0";
          case "primary":
            return "#009688";
          default:
            return "#009688";
        }
      },
      color: "#ffffff",
    },
    "&:active": {
      background: ({ type }: Props) => {
        switch (type) {
          case "default":
            return "#9C27B0";
          case "primary":
            return "#009688";
          default:
            return "#009688";
        }
      },
      color: "#ffffff",
    },
    "&:hover": {
      background: ({ type }: Props) => {
        switch (type) {
          case "default":
            return "#9C27B0";
          case "primary":
            return "#009688";
          default:
            return "#009688";
        }
      },
      color: "#ffffff",
    },
    color: ({ type }: Props) => {
      switch (type) {
        case "primary":
          return "#ffffff";
        case "default":
          return "#ffffff";
        default:
          return "#ffffff";
      }
    },
    borderColor: ({ type }: Props) => {
      switch (type) {
        case "default":
          return "#9C27B0";
        default:
          return "#009688";
      }
    },
    padding: ({ size }: Props) => {
      switch (size) {
        case "small":
          return "8px 25px 8px 25px";
        case "middle":
          return "10px 42px 10px 42px";
        case "large":
          return "10px 54px 10px 54px";
        default:
          return "10px 21px 10px 21px";
      }
    },
    width: ({ size }: Props) => {
      switch (size) {
        case "small":
          return 154;
        case "middle":
          return 234;
        case "large":
          return 314;
        default:
          return 112;
      }
    },
    fontSize: ({ size }: Props) => {
      switch (size) {
        case "small":
          return 18;
        case "middle":
          return 18;
        case "large":
          return 18;
        default:
          return 14;
      }
    },
    fontWeight: "bold",
    height: "auto",
  },
});

const Button: React.FC<Props> = ({
  type = "primary",
  onClick,
  margin,
  size,
  icon,
  className,
  htmlType,
  children,
  loading,
  disabled,
}) => {
  const classes = useStyles({ type, margin, size });

  return (
    <ButtonComponent
      className={`${classes.button} ${className}`}
      htmlType={htmlType || "button"}
      icon={icon}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
    >
      {loading ? "" : children}
    </ButtonComponent>
  );
};

export { Button };
