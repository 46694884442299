import axios from "axios";
import { setCookie, parseCookies } from "nookies";
import { firebase } from "./firebase";

const API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: { "Content-Type": "application/json" },
});

const setToken = (token: any) => {
  API.defaults.headers.common.Authorization = `Bearer ${token}`;
};

API.interceptors.request.use(
  (config) => {
    const cookies = parseCookies(null);
    if (cookies.accessToken) {
      config.headers["Authorization"] = "Bearer " + cookies.accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    return new Promise(async (resolve, reject) => {
      const originalReq = err.config;
      if (
        err.response &&
        err.response.status === 401 &&
        err.config &&
        !err.config._retry
      ) {
        originalReq._retry = true;

        const user = firebase.auth().currentUser;
        if (user) {
          try {
            const idTokenResult = await user.getIdTokenResult(true);
            const idToken = idTokenResult.token;

            setCookie(null, "accessToken", idToken, {
              maxAge: 30 * 24 * 60 * 60,
              path: "/",
            });
            originalReq.headers["Authorization"] = "Bearer " + idToken;
          } catch (error) {
            reject(error);
            return;
          }
          resolve(axios(originalReq));
          return;
        }
      }
      reject(err);
    });
  }
);

const removeToken = () => {
  delete API.defaults.headers.common.Authorization;
};

export { API, setToken, removeToken };
