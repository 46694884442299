import { Typography as Text } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import Link from "next/link";

interface Props {
  type?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "boldBody"
    | "boldBody2"
    | "body1"
    | "body2"
    | "body3"
    | "body4"
    | "body5"
    | "body6"
    | "body7"
    | "button1"
    | "button2"
    | "link1"
    | "title"
    | "title2"
    | "title3"
    | "title4"
    | "title5"
    | "link2"
    | "link3"
    | "link4"
    | "link5"
    | "primaryLink"
    | "blueLink";
  children: any;
  textAlign?: "center" | "left" | "right";
  fontSize?: string;
  lineHeight?: string;
  bold?: boolean;
  color?: string;
  className?: any;
  style?: any;
  target?: "_blank";
  href?: string;
  passHref?: boolean;
}

const useStyles = createUseStyles({
  textClass: {
    display: "block",
    fontStyle: "normal",
    textAlign: ({ textAlign }: Props) => {
      return textAlign === "center"
        ? "center"
        : textAlign === "right"
          ? "right"
          : "left";
    },
    color: ({ color }: Props) => {
      return color ? color : "#1b2e32";
    },
    fontWeight: ({ type, bold }: Props) => {
      switch (type) {
        case "boldBody":
          return "bold";
        case "boldBody2":
          return "bold";
        default:
          return bold ? "bold" : "normal";
      }
    },
    textDecoration: ({ type }: Props) => {
      switch (type) {
        case "link1":
        case "blueLink":
          return "underline";
        case "link2":
        case "primaryLink":
          return "";
        case "link5":
          return "";
        default:
          return "";
      }
    },
    letterSpacing: "0.3px",
    fontSize: ({ type, fontSize }: Props) => {
      switch (type) {
        case "h1":
          return "52px";
        case "h2":
          return "42px";
        case "h3":
          return "32px";
        case "h4":
          return "22px";
        case "h5":
          return "20px";
        case "boldBody":
          return "18px";
        case "boldBody2":
          return "24px";
        case "body1":
          return "18px";
        case "body2":
          return "16px";
        case "body3":
          return "14px";
        case "body4":
          return "12px";
        case "body5":
          return "10px";
        case "body6":
          return "18px";
        case "body7":
          return "24px";
        case "button1":
          return "18px";
        case "button2":
          return "16px";
        case "link1":
          return "18px";
        case "link2":
          return "16px";
        case "link3":
          return "18px";
        case "link4":
          return "24px";
        case "title":
          return "27px";
        case "title2":
          return "36px";
        case "title3":
          return "24px";
        case "title4":
          return "30px";
        default:
          return fontSize ? fontSize : "18px";
      }
    },
    lineHeight: ({ type, lineHeight }: Props) => {
      switch (type) {
        case "h1":
          return "61px";
        case "h2":
          return "48px";
        case "h3":
          return "36px";
        case "h4":
          return "26px";
        case "h5":
          return "30px";
        case "boldBody":
          return "21px";
        case "boldBody2":
          return "36px";
        case "body1":
          return "27px";
        case "body2":
          return "19px";
        case "body3":
          return "21px";
        case "body4":
          return "18px";
        case "body5":
          return "10px";
        case "body6":
          return "21px";
        case "body7":
          return "36px";
        case "button1":
          return "21px";
        case "button2":
          return "19px";
        case "link1":
          return "21px";
        case "link2":
          return "19px";
        case "link3":
          return "27px";
        case "link4":
          return "36px";
        case "title":
          return "39px";
        case "title2":
          return "54px";
        case "title3":
          return "36px";
        case "title4":
          return "43px";
        default:
          return lineHeight ? lineHeight : "21px";
      }
    },
    fontFamily: "Noto Sans JP",
  },
});

const Typography: React.FC<Props> = (props) => {
  const classes = useStyles({ ...props });
  if (
    props.type === "link1" ||
    props.type === "link2" ||
    props.type === "primaryLink" ||
    props.type === "link5" ||
    props.type === "blueLink"
  ) {
    return (
      <Link
        href={props.href ? props.href : ""}
        prefetch={false}
        passHref={props.passHref}
      >
        <a
          className={`${classes.textClass} ${props.className}`}
          target={props.target}
        >
          {props.children}
        </a>
      </Link>
    );
  }
  return (
    <Text
      style={props.style}
      className={`${classes.textClass} ${props.className}`}
    >
      {props.children}
    </Text>
  );
};

export { Typography };
