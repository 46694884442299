import DOMPurify from "isomorphic-dompurify";

const removeFontFamilyFromStyles = (style: string) => {
  return style.replace(/font-family:[^;]+;?/gi, "");
};

const getSanitizedContent = (content: string | Node) =>
  DOMPurify.sanitize(content, {
    ALLOWED_ATTR: [
      "width",
      "height",
      "src",
      "frameborder",
      "allow",
      "style",
      "class",
      "allowfullscreen",
      "colspan",
      "rowspan",
      "id",
      "alt",
    ],
    ADD_ATTR: ["allowfullscreen", "href", "target", "alt", "cite"],
    ADD_TAGS: ["iframe", "a"],
  });

function HTMLContentRender({
  content,
}: Readonly<{
  content: string | Node;
}>) {
  const sanitizedContent = getSanitizedContent(content);
  const cleanedContent = removeFontFamilyFromStyles(sanitizedContent);

  return (
    <article
      className="editor-content"
      dangerouslySetInnerHTML={{
        __html: cleanedContent,
      }}
    />
  );
}

export { getSanitizedContent, HTMLContentRender };
