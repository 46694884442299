import React from "react";
import { createUseStyles } from "react-jss";
import FullLogoWhite from "../../assets/full_logo_white.svg";
import SmallFullLogoWhite from "../../assets/small_full_logo_footer_white.svg";
import { useRouter } from "next/router";

const useStyle = createUseStyles({
  logoWrapper: { cursor: "pointer" },
});

interface Props {
  size: "small" | "normal";
  onClick?: (event?: any) => void;
}

const LogoFooter: React.FC<Props> = (props: Props) => {
  const router = useRouter();
  const classes = useStyle();
  return (
    <div
      className={classes.logoWrapper}
      onClick={() => {
        router.push("/").then(() => window.scrollTo(0, 0));
      }}
    >
      <img
        src={props.size === "small" ? SmallFullLogoWhite : FullLogoWhite}
        alt="footer-logo"
        loading="lazy"
      />
    </div>
  );
};

export { LogoFooter };
