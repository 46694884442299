import React from "react";
import { RECAPTCHA_SITE_KEY } from "./constant";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

interface Props {
  carpetId?: any;
  setForm?: (arg0: boolean) => void;
}

const RecaptchaComponent = (Component: any) => {
  const Recaptcha = ({ children }) => {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
        {children}
      </GoogleReCaptchaProvider>
    );
  };
  return class Higher extends React.Component<Props> {
    render() {
      return (
        <Recaptcha>
          <Component {...this.props} />
        </Recaptcha>
      );
    }
  };
};
export { RecaptchaComponent };
