import React from "react";
import { DatePicker as AntDatePicker } from "antd";
import moment from "moment";
import { createUseStyles } from "react-jss";

interface DatePickerProps {
  value?: string;
  format?: string;
  allowClear?: boolean;
  onChange?: (val: string, name: string) => void;
  size?: "large" | "small" | "default";
  bordered?: boolean;
  className?: any;
  fullWidth?: boolean;
  name?: string;
  placeholder?: string;
  disabled?: any;
}

const useStyles = createUseStyles({
  input: {
    padding: "10px 8.5px 10px 12px",
    fontSize: 14,
    lineHeight: "20px",
    width: (fullWidth: boolean) => {
      return fullWidth && "100%";
    },
    border: "1px solid #D9D9D9",
    borderRadius: "2px",
  },
});

const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  format,
  size,
  className,
  fullWidth,
  name,
  allowClear,
  disabled,
}) => {
  const classes = useStyles(fullWidth);

  const handleDateChange = (date: moment.Moment) => {
    onChange(date.toString(), name);
  };

  return (
    <AntDatePicker
      disabledDate={disabled}
      className={`${className} ${classes.input}`}
      allowClear={allowClear || false}
      size={size === "default" ? null : size}
      placeholder="Select Date"
      format={format}
      value={value && moment(value)}
      onChange={handleDateChange}
    />
  );
};

export type { DatePickerProps };

export { DatePicker };
