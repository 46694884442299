import { Radio as RadioComponent } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

export interface RadioOptions {
  label: string;
  value: string;
}

export interface RadioProps {
  name?: string;
  onChange: (e: any) => void;
  options: RadioOptions[];
  value?: string;
}

const useStyles = createUseStyles({
  radioClass: {
    width: 83,
    height: 27,
    marginRight: 35,
    "& .ant-radio-checked .ant-radio-inner:after": {
      color: "#009688",
      backgroundColor: "#009688",
    },
    "& .ant-radio-checked .ant-radio-inner": {
      borderColor: "#009688",
    },
  },
  lastRadio: {
    "& .ant-radio-checked .ant-radio-inner:after": {
      color: "#009688",
      backgroundColor: "#009688",
    },
    "& .ant-radio-checked .ant-radio-inner": {
      borderColor: "#009688",
    },
  },
});

const Radio: React.FC<RadioProps> = ({
  name,
  onChange,
  options,
  value,
}: RadioProps) => {
  const classes = useStyles();
  const radios = [];
  for (let idx = 0; idx < options.length; idx++) {
    radios.push(
      <RadioComponent
        key={options[idx].value}
        value={options[idx].value}
        className={
          idx < options.length - 1 ? classes.radioClass : classes.lastRadio
        }
      >
        {options[idx].label}
      </RadioComponent>
    );
  }

  return (
    <RadioComponent.Group name={name} onChange={onChange} value={value}>
      {radios}
    </RadioComponent.Group>
  );
};

export { Radio };
