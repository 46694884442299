import { z } from "zod";

export const adminMemberSchema = z.object({
  id: z.number().optional(),
  first_name: z
    .string()
    .min(1, { message: "Please enter." })
    .max(20, { message: "Please enter less than 20 characters." }),
  last_name: z
    .string()
    .min(1, { message: "Please enter." })
    .max(20, { message: "Please enter less than 20 characters." }),
  email: z
    .string()
    .email()
    .min(1, { message: "Please enter." })
    .max(100, { message: "Please enter less than 100 characters." }),
  company: z
    .string()
    .min(1, { message: "Please enter." })
    .max(100, { message: "Please enter less than 100 characters." }),
  role: z.string().min(1, { message: "Please select a role." }),
});

export type adminMemberSchemaType = z.infer<typeof adminMemberSchema>;

export type UserRoleType =
  | "admin"
  | "carpet_editor"
  | "article_editor"
  | "user";

export const UserRole = {
  admin: "admin",
  carpet_editor: "carpet_editor",
  article_editor: "article_editor",
  user: "user",
};
