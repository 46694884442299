import React, { useState, useEffect } from "react";
import { notification } from "antd";
import { ExclamationOutlined } from "@ant-design/icons";

interface InternetStatusProps {
  onOffline?: () => void;
  onOnline?: () => void;
  message: string;
  description: string;
  window: typeof global.window;
  className?: string;
  noNotification?: boolean;
  noMessage?: boolean;
}

const InternetStatus: React.FC<InternetStatusProps> = ({
  onOffline,
  onOnline,
  message,
  description,
  window,
  className = "",
  noNotification = false,
  noMessage = false,
}) => {
  const [hasConnection, setHasConnection] = useState(true);

  useEffect(() => {
    if (!hasConnection && !noNotification) {
      notification.open({
        key: "not-has-connection",
        message,
        description,
      });
    }
  }, [hasConnection]);

  useEffect(() => {
    window.ononline = () => {
      setHasConnection(true);
      onOnline && onOnline();
    };
    window.onoffline = () => {
      setHasConnection(false);
      onOffline && onOffline();
    };
  }, [window]);

  return (
    <>
      {!hasConnection && !noMessage && (
        <div className={className}>
          <p>
            <ExclamationOutlined /> {message}
          </p>
          <p>{description}</p>
        </div>
      )}
    </>
  );
};

export { InternetStatus };
