import React from "react";
import { Select, message } from "antd";
import { API } from "shared";
import { useTranslation } from "next-i18next";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  selectFont: {
    color: "#000",
    fontSize: 14,
    lineHeight: "20px",
    opacity: "0.65",
  },
  selectBorder: {
    background: "#fff",
    border: "1px solid #d9d9d9",
    boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.016)",
    borderRadius: 2,
    height: 32,
    width: 158,
  },
});

interface Props {
  ID: string;
  status: string;
}

const SelectStatus: React.FC<Props> = ({ ID, status }) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChange = async (value: string) => {
    const response = await API.patch(`admin/order-histories/${ID}`, {
      status: value,
    });
    if (response && response.data) {
      message.success("Status updated.");
    } else {
      message.error("Status update failed.");
    }
  };

  return (
    <Select
      className={classes.selectBorder}
      defaultValue={status}
      onChange={handleChange}
    >
      <Option value="waiting">
        <span className={classes.selectFont}>{t("Payment Waiting")}</span>
      </Option>
      <Option value="preparing">
        <span className={classes.selectFont}>{t("Preparing to ship")}</span>
      </Option>
      <Option value="shipped">
        <span className={classes.selectFont}>{t("Shipped")}</span>
      </Option>
    </Select>
  );
};

export { SelectStatus };
